import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import LinedTitle from '../components/LinedTitle';

import { COLORS, FONTS } from '../constants/style';

const breakByWords = text => {
  const words = text.split(' ');

  return `
    <p class="keyword">${words[0]}</p>
    <p class="non-keyword">${words[1]}</p>
    <p class="keyword">${words[2]}</p>
  `;
};

const Page = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  background: ${COLORS.lightGray};
`;

const Section = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .value {
    margin: 2em;
    text-align: center;

    img {
      height: 100px;
      width: 100px;
      margin-bottom: 1.5em;
    }

    p {
      margin: 0;
      font-size: 1.2em;
      line-height: 1.4;
    }
  }
`;

const OurValues = ({ coreValues }) => {
  return (
    <Page>
      <Container>
        <LinedTitle wing>Our Values</LinedTitle>
        <Section>
          {coreValues.map(({ id, icon, text }) => (
            <div key={id} className="value">
              <img src={icon.url} alt={text} />
              <div dangerouslySetInnerHTML={{ __html: breakByWords(text) }} />
            </div>
          ))}
        </Section>
      </Container>
    </Page>
  );
};

export default OurValues;
