import React from 'react';
import styled from 'styled-components';

import { COLORS, FONTS } from '../constants/style';
import LinedTitle from '../components/LinedTitle';
import ArrowLink from '../components/ArrowLink';
import { Container } from 'reactstrap';
import MemberPhoto from '../components/MemberPhoto';

const TeamSection = styled(Container)`
  padding-top: 70px;
  padding-bottom: 70px;

  .content {
    text-align: justify;
    padding: 3em;
    @media (max-width: 576px) {
      padding: 1em;
    }

    h3 {
      margin-bottom: 0;
    }

    .job-title {
      color: ${COLORS.textGray};
    }

    .foreword {
      margin-top: 2em;
    }

    .founder-link {
      color: ${COLORS.textWhite};
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
`;

const Founder = styled.div`
  margin-bottom: 2em;
  .right {
    width: calc(100% + 20px);
    position: relative;
    left: -20px;
    top: 100px;

    img {
      width: 100%;
    }

    .founder-link {
      display: inline-block;
      margin-left: 28px;
    }

    @media (max-width: 767px) {
      order: -1;
      position: static;

      .founder-link {
        display: none;
      }
    }
  }
`;

const Member = styled.div`
  margin-bottom: 2em;
  @media (min-width: 768px) {
    &:nth-child(even) .pic-side {
      order: 2;
    }
  }

  .content .job-title {
    font-size: 1.1em;
  }

  .pic-side {
    padding-top: 4.5em;
    @media (max-width: 576px) {
      padding-top: 1em;
    }
    img {
      width: 100%;
    }
  }
`;

const EdcellentTeam = ({ team }) => {
  const founder = team[0];
  const founderFirstName = founder.name.split(' ')[0];
  return (
    <TeamSection id="edcellent-team">
      <LinedTitle wing>The Edcellent Team</LinedTitle>
      <Founder id={founder.slug} className="row no-gutters">
        <div className="col-md-9">
          <div className="content bg-gold">
            <h3>
              <strong>{founder.name}</strong>
            </h3>
            <h5 className="job-title">{founder.jobTitle}</h5>
            <div
              className="foreword"
              dangerouslySetInnerHTML={{ __html: founder.foreword }}
            />
            <ArrowLink
              to={`/edcellent-team/${founder.slug}`}
              className="founder-link"
            >
              Read {founderFirstName}&apos;s Bio
            </ArrowLink>
          </div>
        </div>
        <div className="col-md-3 right">
          <MemberPhoto
            photoFun={founder.photoFun.url}
            photoMain={founder.photoMain.url}
            alt="Founder"
          />
          <ArrowLink
            to={`/edcellent-team/${founder.slug}`}
            className="founder-link"
          >
            Read {founderFirstName}&apos;s Bio
          </ArrowLink>
        </div>
      </Founder>
      {team.map((member, index) => {
        if (index === 0) return '';

        const memberFirstName = member.name.split(' ')[0];

        return (
          <Member id={member.slug} key={member.id} className="row no-gutters">
            <div className="col-md-3 pic-side">
              <MemberPhoto
                photoFun={member.photoFun.url}
                photoMain={member.photoMain.url}
                alt={memberFirstName}
              />
            </div>
            <div className="col-md-9">
              <div className="content">
                <h3>
                  <strong>{member.name}</strong>
                </h3>
                <h5 className="job-title">{member.jobTitle}</h5>
                <div
                  className="foreword"
                  dangerouslySetInnerHTML={{ __html: member.foreword }}
                />
                <ArrowLink
                  to={`/edcellent-team/${member.slug}`}
                  className="member-link"
                >
                  Read {memberFirstName}&apos;s Bio
                </ArrowLink>
              </div>
            </div>
          </Member>
        );
      })}
    </TeamSection>
  );
};

export default EdcellentTeam;
