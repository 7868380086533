import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { COLORS, FONTS } from '../constants/style';

const MissionContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;

  .blockquote {
    position: relative;
    /* margin-top: 50px; */
    /* margin-left: 30px; */
    /* margin-bottom: 100px; */
    color: ${COLORS.textMain};
    font-size: 3.8rem;
    font-style: normal;

    img.open-quote {
      position: absolute;
      left: -38px;
      top: -50px;
      height: 70px;
      z-index: -1;
    }

    .title {
      font-weight: 900;
      font-family: ${FONTS.secondary};
      color: ${COLORS.darkGray};
      letter-spacing: 3px;
      line-height: 1.2;
    }
  }

  .right {
    position: relative;
    .api-content {
      font-size: 1.3rem;
      text-align: justify;
      position: relative;
    }

    img.close-quote {
      height: 70px;
      position: absolute;
      right: 0px;
      bottom: -45px;
      z-index: -1;
      transform: rotate(180deg);
    }
  }
`;

const OurMission = ({ content }) => {
  return (
    <MissionContainer>
      <div className="row no-gutters align-items-center">
        <div className="offset-md-1 col-md-5 flex-center">
          <blockquote className="blockquote">
            <img src="/img/quote.svg" alt="quote icon" className="open-quote" />
            <div className="title">
              OUR
              <br />
              MISSION
            </div>
          </blockquote>
        </div>
        <div className="col-md-5 right">
          <div
            className="api-content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <img src="/img/quote.svg" alt="quote icon" className="close-quote" />
        </div>
      </div>
    </MissionContainer>
  );
};

export default OurMission;
