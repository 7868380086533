import React, { useState } from 'react';

const MemberPhoto = ({ photoMain, photoFun, ...props }) => {
  const [hover, setHover] = useState(false);

  const showFun = () => {
    setHover(true);
  };

  const showMain = () => {
    setHover(false);
  };
  return (
    <img
      onMouseEnter={showFun}
      onMouseOut={showMain}
      src={hover ? photoFun : photoMain}
      alt="Fun"
      {...props}
    />
  );
};

export default MemberPhoto;
