import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FONTS, COLORS } from '../constants/style';

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 0.5em;
  font-family: ${FONTS.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${COLORS.darkGold};
  &:hover {
    color: ${COLORS.brightGold};
  }
`;

const ArrowLink = ({ children, to, left = false, ...props }) => {
  if (left) {
    return (
      <StyledLink to={to} {...props}>
        ⟵&nbsp;&nbsp;&nbsp;{children}
      </StyledLink>
    );
  } else {
    return (
      <StyledLink to={to} {...props}>
        {children}&nbsp;&nbsp;&nbsp;⟶
      </StyledLink>
    );
  }
};

export default ArrowLink;
