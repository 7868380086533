import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';

import SEO from '../components/SEO';
import OurMission from '../containers/OurMission';
import EdcellentTeam from '../containers/EdcellentTeam';
import OurValues from '../containers/OurValues';
import { FONTS, COLORS } from '../constants/style';

const Page = styled.div`
  padding-top: 120px;
  margin-bottom: 120px;
`;

const AboutUs = ({ data }) => {
  const { seoMetaTags, ourMission, coreValues, theEdcellentTeam } = data.page;

  return (
    <>
      <SEO meta={seoMetaTags} />
      <Page>
        <OurMission content={ourMission} />
        <OurValues coreValues={coreValues} />
        <EdcellentTeam team={theEdcellentTeam} />
      </Page>
    </>
  );
};

export default AboutUs;

export const query = graphql`
  {
    page: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      theEdcellentTeam {
        id
        slug
        biography
        foreword
        jobTitle
        name
        photoFun {
          url
        }
        photoMain {
          url
        }
      }
      coreValues {
        id
        text
        icon {
          url
        }
      }
      ourMission
    }
  }
`;
